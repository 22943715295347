import language from "@language"
import moment from "moment"
import "moment/locale/es"

export const cityStateZip = (city, state, zipCode) => {
  return `${city}, ${state} ${zipCode}`
}

export const truncateText = (text, charCount = 78) => {
  return `${text.slice(0, charCount)}...`
}

export const fullDateFormat = (lang = "es", date) => {
  if (!date) return ""
  return moment(date, "MM-DD-YYYY")
    .locale(lang)
    .format(language[lang].sentences.fullDateFormat)
}

export const shortDateFormat = (lang = "es", date) => {
  if (!date) return ""
  return moment(date, "MM-DD-YYYY")
    .locale(lang)
    .format(language[lang].sentences.shortDateFormat)
}

export const URLify = string => {
  return string.trim().toLowerCase().replace(/\s/g, "-")
}

export const normalizeText = string => {
  // returns a string with un-accented characters
  return string
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
}
