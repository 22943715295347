import React from "react"
import PropTypes from "prop-types"
import BreadcrumbText from "@components/common/BreadcrumbText"
import language from "@language"
import HeadingText from "@components/common/HeadingText"

import classnames from "classnames"

import "./style.scss"

const SimpleBreadcrumbHero = props => {

  const parseImageUrl = (url) => {
    const parsedUrl = new URL(url)
    parsedUrl.searchParams.set('w', '2000')
    return parsedUrl
  }

  return (
    <div
      className={classnames(
        "simple-breadcrumb-hero",
        `simple-breadcrumb-hero--${props.size}`
      )}
      style={{
        backgroundImage:
          props.image && props.image.sourceUrl
            ? `url(${parseImageUrl(props.image.sourceUrl)})`
            : "",
      }}
    >
      {props.image && <div className={`simple-breadcrumb-hero__overlay ${props.overlayType}`}></div>}
      <div className="page-wrap">
        {!!(props.overline || props.title) && (
          <div className="simple-breadcrumb-hero__text">
            {props.overline && (
              <HeadingText
                className="simple-breadcrumb-hero__overline"
                color={props.overlayType === "full" ? "light" : "primary-light"}
                size="xs"
                text={props.overline}
              />
            )}
            {props.title && (
              <HeadingText
                className="simple-breadcrumb-hero__title"
                color="light"
                size="xl"
                text={props.title}
              ></HeadingText>
            )}
          </div>
        )}

        <div className="simple-breadcrumb-hero__breadcrumbs">
          <BreadcrumbText
            link1={{ url: "/", title: language[props.language].start }}
            link2={props.breadcrumbLink2}
            pageTitle={props.pageTitle}
          />
        </div>
      </div>
    </div>
  )
}

SimpleBreadcrumbHero.propTypes = {
  breadcrumbLink2: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
  }),
  image: PropTypes.shape({
    altText: PropTypes.string,
    sourceUrl: PropTypes.string,
  }),
  pageTitle: PropTypes.string,
  language: PropTypes.string,
  overline: PropTypes.string,
  overlayType: PropTypes.oneOf(["full", "partial"]),
  title: PropTypes.string,
  size: PropTypes.oneOf(["default", "sm"]),
}
SimpleBreadcrumbHero.defaultProps = {
  language: "es",
  size: "default",
  overlayType: "partial"
}

export default SimpleBreadcrumbHero
