import React from "react"
import PropTypes from "prop-types"
import UniversalLink from "@components/common/UniversalLink"

import "./style.scss"

const BreadcrumbText = props => {
  return (
    <div className="breadcrumb-text">
      {props.link1 && (
        <UniversalLink
          className={`text-link light-text system-${props.systemTextSize}`}
          href={props.link1.url}
        >
          {props.link1.title}
        </UniversalLink>
      )}
      {props.link2 && (
        <>
          <span className={`system-${props.systemTextSize} light-text`}>
            &nbsp;/&nbsp;
          </span>

          <UniversalLink
            className={`text-link light-text system-${props.systemTextSize}`}
            href={props.link2.url}
          >
            {props.link2.title}
          </UniversalLink>
        </>
      )}
      {props.pageTitle && (
        <>
          <span className={`system-${props.systemTextSize} light-text`}>
            &nbsp;/&nbsp;
          </span>
          <span
            className={`breadcrumb-text__current light-text system-${props.systemTextSize}`}
          >
            {props.pageTitle}
          </span>
        </>
      )}
    </div>
  )
}

BreadcrumbText.propTypes = {
  link1: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
  }),
  link2: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
  }),
  pageTitle: PropTypes.string,
  systemTextSize: PropTypes.oneOf(["sm", "lg"]),
}
BreadcrumbText.defaultProps = {
  systemTextSize: "sm",
}

export default BreadcrumbText
