import React from "react"
import PropTypes from "prop-types"
import Markdown from "markdown-to-jsx"
import { preFilterMarkdown } from "@utilities/markdownUtils"

import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"
import UniversalLink from "@components/common/UniversalLink"
import CustomImage from "@components/common/CustomImage"
import EmbedVideo from "@components/common/EmbedVideo"

import classnames from "classnames"

import "./style.scss"

const ArticleMarkdownContent = props => {
  return (
    <div className={classnames("article-markdown-content", props.className)}>
      <Markdown
        options={{
          overrides: {
            p: {
              component: BodyText,
              props: {
                className: props.bodyStackSize,
                color: "dark-gray",
                size: "md2",
              },
            },
            span: {
              component: BodyText,
              props: {
                className: "mb-40px",
                color: "dark-gray",
                size: "md2",
              },
            },
            li: {
              component: BodyText,
              props: {
                className: "mb-10px",
                color: "dark-gray",
                element: "li",
                size: "md2",
              },
            },
            a: {
              component: UniversalLink,
              props: {
                color: "link",
              },
            },
            blockquote: {
              component: BodyText,
              props: {
                color: "primary-dark",
                element: "blockquote",
                className: "article-markdown-content__blockquote",
              },
            },
            h1: {
              component: HeadingText,
              props: {
                className: "mb-20px",
                color: props.headingColor,
                element: "h2",
                size: "lg",
              },
            },
            h2: {
              component: HeadingText,
              props: {
                className: "mb-20px",
                color: props.headingColor,
                element: "h3",
                size: "sm",
              },
            },
            h3: {
              component: BodyText,
              props: {
                className: "mb-20px",
                color: props.headingColor,
                element: "h4",
                size: "md",
              },
            },
            h4: {
              component: BodyText,
              props: {
                className: "mb-20px",
                color: props.headingColor,
                element: "h4",
                size: "md",
              },
            },
            h5: {
              component: BodyText,
              props: {
                className: "mb-20px",
                color: props.headingColor,
                element: "h4",
                size: "md",
              },
            },
            h6: {
              component: BodyText,
              props: {
                className: "mb-20px",
                color: props.headingColor,
                element: "h4",
                size: "md",
              },
            },
            img: {
              component: CustomImage,
              props: {
                className: "responsive-image mb-40px",
                width: 800,
              },
            },
            iframe: {
              component: EmbedVideo,
              props: {
                className: "responsive-image mb-40px",
                width: 800,
              },
            },
          },
        }}
      >
        {preFilterMarkdown(props.content) || ""}
      </Markdown>
    </div>
  )
}

ArticleMarkdownContent.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  bodyStackSize: PropTypes.string,
  headingColor: PropTypes.string,
}
ArticleMarkdownContent.defaultProps = {
  bodyStackSize: "mb-40px",
  headingColor: "primary",
}

export default ArticleMarkdownContent
