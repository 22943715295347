import React from "react"
import PropTypes from "prop-types"
import UniversalLink from "@components/common/UniversalLink"

import { ReactComponent as ChevronLeft } from "@images/icons/chevron-left.svg"
import { ReactComponent as ChevronRight } from "@images/icons/chevron-right.svg"

import "./style.scss"

const LargeArrowButton = props => {
  return (
    <UniversalLink className="large-arrow-button" href={props.uri}>
      {props.direction === "left" && (
        <>
          <div className={`large-arrow-button__icon ${props.direction}`}>
            <ChevronLeft />
          </div>
          {props.children}
        </>
      )}
      {props.direction === "right" && (
        <>
          {props.children}
          <div className={`large-arrow-button__icon ${props.direction}`}>
            <ChevronRight />
          </div>
        </>
      )}
    </UniversalLink>
  )
}

LargeArrowButton.propTypes = {
  direction: PropTypes.oneOf(["left", "right"]),
  uri: PropTypes.string,
}
LargeArrowButton.defaultProps = {
  direction: "left",
}

export default LargeArrowButton
