import React from "react"
import PropTypes from "prop-types"

import "./style.scss"

const EmbedVideo = props => {
  return (
    <span className="embed-video">
      <iframe title={props.title} src={props.src} />
    </span>
  )
}

EmbedVideo.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string,
}
EmbedVideo.defaultProps = {
  title: "Video",
}

export default EmbedVideo
